<template>
  <div class="ml-4 mt-4">
    <span class="text-subtitle-1">{{ $tc("global.prestador", 2) }}</span>
    <v-row dense class="mt-1">
      <v-col cols="12" md="3">
        <SearchComboboxFornecedores
          :readonly="disabled"
          :fornecedor_sync.sync="selectedPrestador"
          prestadores
          :key="keyPrestadores"
        />
      </v-col>
      <v-col cols="12" md="2" class="mx-4">
        <v-text-field
          :disabled="disabled"
          v-model="valorServico"
          :placeholder="$tc('global.valorServico')"
          hide-details
          dense
          solo
          flat
          outlined
          :append-icon="invalidPrecoItem ? 'mdi-lock-alert-outline' : ''"
          @click:append="openDialogAuth(false)"
          :error-messages="erroPrecoItem"
          @keydown.enter.prevent="validaValorProduto"
          ref="valorServico"
          v-currency="vCurrencyOptions"
        >
        </v-text-field>
      </v-col>
      <v-col cols="4" md="1">
        <v-checkbox
          :disabled="disabled"
          v-model="terceirizado"
          :label="$tc('global.terceirizado')"
          class="mt-1"
        >
        </v-checkbox>
      </v-col>
      <v-col class="ml-4">
        <v-btn outlined color="button_1" @click="addPrestador">{{ $tc("global.add") }}</v-btn>
      </v-col>
    </v-row>
    <v-card flat class="pr-4">
      <v-divider class="mt-2"></v-divider>
      <v-data-table
        :headers="headers"
        :items="prestadores"
        :items-per-page="-1"
        hide-default-footer
        class="data-tables data-tables__row-click"
      >
      <template v-slot:item.terceirizado="{ item }">
        <span v-if="item.terceirizado">Sim</span>
        <span v-else>Não</span>
      </template>
      <template v-slot:item.valor="{ item, index }">
        <div v-show="isItemEditing && index === indexItemEditing">
          <div class="d-flex align-center justify-center">
            <v-text-field
              :disabled="disabled"
              v-model="itemEditingPreco"
              :placeholder="$tc('global.preco')"
              hide-details
              outlined
              dense
              class="input-low-height"
              ref="itemEditingPreco"
              :error="invalidPrecoItemEdit"
              :append-icon="
                invalidPrecoItemEdit ? 'mdi-lock-alert-outline' : ''
              "
              @click:append="openDialogAuth(true)"
              v-currency="vCurrencyOptions"
              @keydown.enter.prevent="updateValorServico(index)"
            ></v-text-field>
            <v-tooltip v-if="item.preco_alterado" bottom color="darkblue">
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  size="large"
                  class="ml-1"
                  :color="
                    controlePrecos[index].preco_alterado ? 'error' : 'success'
                  "
                  >mdi-cash-lock</v-icon
                >
              </template>
              <span v-show="controlePrecos[index].preco_alterado">
                {{ $tc("global.necessarioConfirmarPreco") }} <br />
              </span>
              <span>
                {{ $tc("global.precoNovoCliente") }}
                <span v-if="selectedMoeda && selectedMoeda.sigla === 'G$'">
                  {{ item.novo_preco | guarani }}
                </span>
                <span v-else>{{ item.novo_preco | currency }} </span>
              </span>
            </v-tooltip>
          </div>
        </div>
        <div v-show="!isItemEditing && index != indexItemEditing">
          <span v-if="selectedMoeda && selectedMoeda.sigla === 'G$'">
            {{ item.valor | guarani }}
          </span>
          <span v-else>{{ item.valor | currency }} </span>
          <v-tooltip v-if="item.preco_alterado" bottom color="darkblue">
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                size="large"
                class="ml-1"
                :color="
                  controlePrecos[index].preco_alterado ? 'error' : 'success'
                "
                >mdi-cash-lock</v-icon
              >
            </template>
            <span v-show="controlePrecos[index].preco_alterado">
              {{ $tc("global.necessarioConfirmarPreco") }} <br />
            </span>
            <span>
              {{ $tc("global.precoNovoCliente") }}
              <span v-if="selectedMoeda && selectedMoeda.sigla === 'G$'">
                {{ item.novo_preco | guarani }}
              </span>
              <span v-else>{{ item.novo_preco | currency }} </span>
            </span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:item.actions="{ item, index }">
          <template
          v-if="isItemEditing && index === indexItemEditing"
          >
            <v-btn
              color="green lighten-1"
              icon
              small
              @click.stop="updateValorServico(index)"
            >
              <v-icon size="20">mdi-content-save</v-icon>
            </v-btn>
          </template>
          <template v-else>
            <v-btn
              icon
              small
              :disabled="disabled"
              @click.stop="StartEditItem(item, index)"
            >
              <v-icon size="20">mdi-pencil-outline</v-icon>
            </v-btn>
            <v-btn
              color="red lighten-2"   
              icon
              :disabled="disabled"
              small
              @click.stop="deletarItem(index)"
            >
              <v-icon size="20">mdi-delete-outline</v-icon>
            </v-btn>
          </template>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <div class="d-flex">
        <v-spacer></v-spacer>
        <div style="min-width: 45%">
          <div class="d-flex py-2">
            <span class="text-subtitle-1 grey--text">{{
              $tc("global.total")
            }}</span>
                        <v-spacer></v-spacer>
            <span class="mr-16 pr-13">
              <span v-if="selectedMoeda && selectedMoeda.sigla === 'G$'">
                {{ totalPrestadoresComputed | guarani }}
              </span>
              <span v-else>{{ totalPrestadoresComputed | currency }} </span>
            </span>
          </div>
          <v-divider></v-divider>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { fetchUsersDeposito } from "@/api/usuarios/usuarios.js";
import { mapState } from "vuex";
export default {
  name: "OrdemServicoManualPrestadores",
  props: {
    selectedMoeda: {
      type: Object,
    },
    selectedLista: {
      type: Object,
      default: () => ({}),
    },
    prestadores: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    SearchComboboxFornecedores: () =>
      import(
        "@/components/fields/combobox/fornecedores/SearchComboboxFornecedores.vue"
      ),
  },

  data() {
    return {
      loading: false,
      depositos: [],
      selectedDeposito: null,
      selectedProduto: null,
      selectedPrestador: null,
      qtde_produto: 1,
      controlePrecos: [],
      estoque_disponivel: 0,
      loadingEstoque: false,
      terceirizado: false,
      isItemEditing: false,
      indexItemEditing: null,
      valorServico: null,
      erroPrecoItem: "",
      invalidPrecoItem: false,
      vendaItemPreco: null,
      invalidPrecoItemEdit: false,
      itemEditingPreco: null,
      itemEditing: null,
      keyPrestadores: 0,
    };
  },

  computed: {
    ...mapState("Usuario", {
      user_id: (state) => state.usuario.id,
    }),
    totalPrestadoresComputed() {
      return this.prestadores.reduce((acc, item) => {
        return Number(acc) + Number(item.valor);
      }, 0);
    },
    vCurrencyOptions() {
      return {
        precision: this.selectedMoeda
          ? this.selectedMoeda.sigla === "G$"
            ? 0
            : undefined
          : "",
        distractionFree: false,
      };
    },

    headers() {
      return [
        {
          text: this.$tc("global.nome"),
          value: "fornecedor_nome",
          width: "40vw",
        },
        {
          text: this.$tc("global.terceirizado"),
          value: "terceirizado",
          width: "5vw",
          sortable: false,
        },
        {
          text: this.$tc("global.valorServico"),
          align: "right",
          value: "valor",
          width: "10vw",
        },
        {
          text: this.$tc("global.del"),
          value: "actions",
          width: "100px",
          sortable: false,
          align: "right",
        },
      ];
    },
  },
  watch: {
    itemEditingPreco() {
      if (this.isItemEditing) {
        this.itemEditing.valor = this.$ci.parse(
          this.itemEditingPreco,
          this.vCurrencyOptions
        );
      }
    },
  },
  methods: {
    async getDepositos() {
      const response = await fetchUsersDeposito(`?users_id=${this.user_id}`);
      this.depositos = response;
      this.selectedDeposito = this.depositos.find((deposito) => {
        return deposito.principal == true;
      });
    },
    updateValorServico(index) {
      let produto_preco = (this.prestadores[this.indexItemEditing].valor =
        this.itemEditing.valor);

      if (
        produto_preco === null ||
        produto_preco === undefined ||
        produto_preco === ""
      ) {
        this.invalidPrecoItemEdit = true;
      } else {
        this.prestadores[this.indexItemEditing].valor =
        this.itemEditing.valor;
        this.prestadores[this.indexItemEditing].qtde =
        this.itemEditing.qtde;
        this.isItemEditing = false;
        this.itemEditing = null;
        this.indexItemEditing = null;
      }
      this.confirmarMudancaPreco(index);
    },
    deletarItem (index) {
      this.prestadores.splice(index, 1);
    },
    confirmarMudancaPreco(index){
      this.controlePrecos[index].preco_alterado = false;
      this.key++;
    },
    addPrestador() {
      this.selectedPrestador.valor = this.$ci.parse(this.valorServico, this.vCurrencyOptions)  ? this.$ci.parse(this.valorServico, this.vCurrencyOptions) : 0;
      this.selectedPrestador.terceirizado = this.terceirizado;
      this.selectedPrestador.fornecedor_nome = this.selectedPrestador.nome;
      this.prestadores.push(this.selectedPrestador);
      this.selectedPrestador = null;
      this.valorServico = null;
      this.terceirizado = false;
      this.keyPrestadores++;
    },
    StartEditItem(item, index) {
      this.isItemEditing = true;
      this.itemEditing = { ...item };
      this.indexItemEditing = index;
      this.$ci.setValue(this.$refs.itemEditingPreco, item.valor);
    },
  },

  mounted() {
    this.getDepositos();
  },
};
</script>
